<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="4"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                    Mot de passé oublié ?
                  </p>
                  <p class="mb-2">
                    Rentrez l'adresse e-mail associé à votre compte
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form @submit="onSubmit">
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email associé à votre compte"
                      placeholder="Email associé à votre compte"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Réinitialisez votre mot de passe
                    </v-btn>
                  </v-form>
                </v-card-text>
                <v-card-text
                  v-if="successMessage || errorMessage"
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <span
                    v-show="successMessage"
                    class="green"
                  >
                    {{ successMessage }}
                  </span>
                  <span
                    v-show="errorMessage"
                    class="red"
                  >
                    {{ errorMessage }}
                  </span>
                </v-card-text>
                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <a href="/login">Revenir à la page de connexion</a>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  setup() {
    const email = ref('')
    const errorMessage = ref(null)
    const successMessage = ref(null)

    const onSubmit = event => {
      event.preventDefault()
      const values = {
        email: email.value,
      }
      store
        .dispatch('user/RESET_PASSWORD_REQUEST', values)
        .then(() => {
          errorMessage.value = null
          successMessage.value = "Demande de réinitialisation de mot de passe envoyée. Si vous n'avez pas reçu d'email, vérifiez votre dossier spam."
        })
        .catch(e => {
          successMessage.value = ''
          switch (e.code) {
            case 'auth/user-not-found':
              errorMessage.value = "Cet email n'est associé à aucun compte"
              break
            default:
              errorMessage.value = `Une erreur est survenue (${e.message}) Réessayez`
              break
          }
        })
    }

    return {
      email,
      errorMessage,
      successMessage,

      // Functions
      onSubmit,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.red {
  color: red;
}
.green {
  color: rgb(65, 163, 65);
  font-weight: 600;
}
</style>
